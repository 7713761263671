exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ac-repaire-and-installation-js": () => import("./../../../src/pages/ac-repaire-and-installation.js" /* webpackChunkName: "component---src-pages-ac-repaire-and-installation-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-comprehensive-amc-js": () => import("./../../../src/pages/comprehensive-amc.js" /* webpackChunkName: "component---src-pages-comprehensive-amc-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-non-comprehensive-amc-js": () => import("./../../../src/pages/non-comprehensive-amc.js" /* webpackChunkName: "component---src-pages-non-comprehensive-amc-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-brand-single-js": () => import("./../../../src/templates/brand-single.js" /* webpackChunkName: "component---src-templates-brand-single-js" */),
  "component---src-templates-product-single-js": () => import("./../../../src/templates/product-single.js" /* webpackChunkName: "component---src-templates-product-single-js" */),
  "component---src-templates-project-single-js": () => import("./../../../src/templates/project-single.js" /* webpackChunkName: "component---src-templates-project-single-js" */)
}

